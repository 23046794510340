import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`At `}<a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}>{`Dashbot`}</a>{`, having processed nearly 60 billion messages, we have seen a wide variety of usecases for conversational interfaces – everything from customer service, retail, banking, travel, games, news, religion, dating, and much more.`}</p>
    <p>{`As the year comes to a close, we wanted to share the insights of industry leaders in the space.`}</p>
    <p>{`We surveyed experts in conversational interfaces to see what they thought were the biggest trends of 2018 as well as their predictions for 2019.`}</p>
    <p><strong parentName="p">{`Our panelists include:`}</strong></p>
    <table><tbody><tr><td width="50%">- Alex Weidauer  
  Co-founder/CEO  
  [Rasa](https://rasa.com/)
- Allen Firstenberg  
  [Objective Consulting](https://spiders.com)
- Andy Mauro  
  CEO  
  [Automat](https://www.automat.ai/)
- Andy Webb  
  Head of Product – Voice + AI  
  [BBC](https://www.bbc.com/)
- Audrey Wu  
  Co-founder/CEO  
  [Convrg](https://convrg.io/)
- Austin Arensberg  
  Principal  
  [Scrum Ventures ](https://scrum.vc/)
- Ben Parr  
  Co-founder/President  
  [Octane](https://octaneai.com/)
- Brandon Kaplan  
  Founder / CEO  
  [Skilled Creative](http://www.skilledcreative.com/)
- Bree Glaeser  
  Director of Shopper Innovation  
  &amp; Voice Practice Lead  
  [The Mars Agency](http://www.themarsagency.com)
- Bret Kinsella  
  Editor  
  [Voicebot.ai ](https://voicebot.ai/)
- Connor Cirillo  
  Conversational Marketing Manager  
  [Hubspot](https://www.hubspot.com/)
- Christian Brucculeri  
  CEO  
  [Snaps](https://snaps.io/)
- Dave Witting  
  Partner  
  [Rocket Insights](http://rocketinsights.com/)
          </td><td width="50%">- Jenny Gao  
  Associate  
  [Bessemer Venture Partners](https://www.bvp.com/)
- Lauren Kunze  
  CEO  
  [Pandorabots](https://home.pandorabots.com)
- Lucas Ives  
  VP of Engineering  
  [Pullstring](https://www.pullstring.com/)
- Max Child  
  Co-founder  
  [Volley](https://volleythat.com/)
- Mehfuz Hossain  
  CEO/Co-founder  
  [Smartloop](https://smartloop.ai/)
- Nico Acosta  
  Director of Product  
  [Twilio](https://www.twilio.com/)
- Pat Higbie  
  Co-founder/CEO  
  [XAPPmedia](https://xappmedia.com/)
- Ryan Maynard  
  CEO  
  [ChatDynamo](https://www.chatdynamo.com/)
- Scott Ganz  
  Principal Conversation Designer  
  [Intuit](https://www.intuit.com/)
- Sergio Cruz Passos  
  Co-founder/CTO  
  [Take/Blip](https://www.blip.ai/)
- Stephane Nguyen  
  VP Product Design  
  [Assist](https://www.assi.st/)
- Will Hall  
  CCO  
  [RAIN](https://rain.agency/)
          </td></tr></tbody></table>
    <h2>{`Biggest Trends of 2018`}</h2>
    <p>{`Three common themes emerged from our group of experts in regards to the biggest trends of 2018 – the rise of voice interfaces, enterprises investing more in conversational interfaces, and the use of conversational interfaces within e-commerce usecases.`}</p>
    <h3>{`The Rise of Voice`}</h3>
    <p>{`The interest in voice interfaces really took off in 2018. We saw this at CES at the beginning of the year with all the Google Assistant activations and the proliferation of devices incorporating Alexa and Google Assistant – mirrors, cars, microwaves, and more. Advancements in voice assistants was one of the most common trends in 2018 cited by our panel of experts.`}</p>
    <p>{`As pointed out by Pat Higbie of XAPPMedia, a duopoly emerged between Alexa and Google Assistant. Both platforms continue to push each other and expand both in features and reach – expanding geographically through supporting more languages and expanding more broadly through multi-modal and additional device support. As Max Child of Volley adds, the platforms are starting to become “real” through monetization and retention tools.`}</p>
    <p>{`Consumer adoption of these two platforms has led to enterprises needing to support both as part of an overall voice strategy. As Connor Cirillo of Hubspot mentions, personal assistant devices are becoming the “center of gravity” for consumers – becoming entrenched in consumers lives. We saw this in `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2018/11/19/how-consumers-really-use-alexa-and-google-home-voice-assistants/"
      }}>{`our survey of consumers`}</a>{` as well – 65% responded the device has had an impact on their behaviors and daily routines.`}</p>
    <p>{`As Brandon Kaplan of Skilled Creative points out, we’re still in the early days of voice and haven’t yet reached the potential of what the platforms can do. However, the devices are having an impact on the next generation of consumers – ones that will expect to be able to speak to assistants for their needs.`}</p>
    <h3>{`Conversational Commerce`}</h3>
    <p>{`Our panel of experts saw e-commerce as another area with a lot of growth over the past year.`}</p>
    <p>{`Enterprises have embraced conversational interfaces for the full e-commerce lifecycle – pre sales to post. As Ben Parr of Octane mentions, merchants are using chatbots for everything from customer acquisition, to retargeting, and post sales customer service.`}</p>
    <p>{`As Ryan Maynard of ChatDynamo points out, chatbots are helping drive revenues for businesses and becoming part of the overall e-commerce strategy. Following up to shopping cart abandonment and re-engagement are key opportunities for chatbots. According to Ryan, re-engagement through a Facebook Messenger bot is more effective than email or ad retargeting. Ben adds, shopify stores have seen 3 to 25% increases in revenue by adding a chatbot.`}</p>
    <h3>{`Enterprise Investment in Conversational Interfaces`}</h3>
    <p>{`While conversational interfaces are still relatively new, 2018 saw enterprises investing more seriously in them. As Sergio Passos of Blip notes, brands are moving more from Proof of Concepts to ROI based projects.`}</p>
    <p>{`Enterprises are also expanding their application of chatbots for more use-cases. As Christian Brucculeri of Snaps points out, enterprises are expanding from single use cases to end-to-end experiences to cover more of the customer journey – from awareness and engagement, to post purchase customer care. Mehfuz Hossain of SmartLoop adds that enterprises are understanding the value of these interfaces and expanding the use from marketing to customer service.`}</p>
    <p>{`Not only are enterprises expanding their conversational use cases, they’re investing significantly in in-house teams as Alex Weidauer of Rasa points out. We see this in our own customer interactions – more and more brands are building out conversation specific teams.`}</p>
    <p>{`In addition to e-commerce and voice related use-cases, our panel of experts are seeing quite a bit of interest in Banking and Finance as both Scott Ganz of Intuit and Alex mention.`}</p>
    <p>{`Given the advancements in the platforms and increased investment by enterprises, Lauren Kunze of Pandorabots summarizes the trends in 2018 quite well – “Bots are here to stay.”`}</p>
    <h2>{`Predictions for 2019`}</h2>
    <p>{`Three themes emerged from our experts predictions: growth in voice assistants, improvements in AI, and better conversational apps.`}</p>
    <h3>{`Growth in Voice`}</h3>
    <p>{`Just as the rise in voice was one of the biggest trends of 2018, the growth in this space is one of the most cited predictions. As voice assistants, and smart speakers, continue to take off, better use cases, more functionality, and new challenges will emerge.`}</p>
    <p>{`Our panel of experts see growth in multi-modal experiences, along with new use cases for these types of devices. Scott Ganz predicts designers and developers will take multi-modal much more serious, and while it may not quite reach mainstream, it’s on its way. Will Hart of Rain, sees the multi-modal functionality opening the door to new ad units.`}</p>
    <p>{`Another voice trend our experts see is the Google Assistant gaining traction. Audrey Wu of Convrg predicts Google will even gain traction on iOS as it’s a better experience than Apple’s Siri. David Witting of Rocket Insights believes Google will close the gap with Amazon as well.`}</p>
    <p>{`As Alexa and Google Assistant add more features and functionality, new types of challenges may emerge. Lucas Ives of PullString ponders whether Alexa will be more of a “concierge” or an “oracle” and what impact will that have on brands. Pat of XAPPMedia believes the implicit innovocation of Google Actions and the nameless innovocation of Alexa Skills is essential for discovery, but will lead to increased competition to be number one in a particular category.`}</p>
    <h3>{`Better Conversational Experiences`}</h3>
    <p>{`Given the space is relatively new, many enterprises have not yet fully taken advantage of the conversational nature when building applications. Much like in the early days of iOS when the initial apps tended to be relatively basic and not quite useful – e.g. the “fart apps” – many of the initial chatbots and voice skills were in a similar vein.`}</p>
    <p>{`Our experts see this changing – enterprises developing better user experiences that leverage the conversational nature of the interfaces.`}</p>
    <p>{`Not only will brands develop better experiences, they will expand the use-cases into more places. Our experts see brands moving out of Proof of Concepts to focus on usecases they can do really well. One common usecase that stands out in the predictions relates to customer service. In fact, Nico Acosta of Twilio predicts conversational IVRs will become the norm in the future.`}</p>
    <h3>{`Improvements in Ecosystem`}</h3>
    <p>{`Related to the above predictions, our experts see improvements in AI, tools, platforms, and interoperability in the near future.`}</p>
    <p>{`The underlying AI will get better in the future. One thing our experts point out is that not all chatbots are AI based. That being said, we see continual improvements in Natural Language processing and AI that can help improve the user experiences in the future.`}</p>
    <p>{`One theme is the increased opening of platforms – for example Facebook opening WhatsApp similarly to Messenger.`}</p>
    <p>{`As more platforms open up and existing platforms add more functionality, having standards can be helpful. Andy Webb of the BBC predicts the underlying platforms will continue to build common interaction models.`}</p>
    <h3>{`Industries to Watch`}</h3>
    <p>{`We asked the experts which industries they think will make the biggest advancements in conversational interfaces in 2019. The most common choices were Banking / Finance, Automotive, Customer Service, and Retail / Shopping.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/12/02214002/industry-predictions.png",
        "alt": null
      }}></img></p>
    <h2>{`Conclusions`}</h2>
    <p>{`Some of the biggest advancements over the past year as well as predicted future growth is in voice interfaces. At Dashbot, we are strong believers in voice – it’s the natural way humans communicate. As we have mentioned in previous articles, if you remember the videos of two-year-olds swiping iPhones and iPads, the same thing is happening with devices like Alexa and Google Home – kids know how to interact.`}</p>
    <p>{`As many of our experts have pointed out, and we see first hand in the analytics – customer service, across verticals like banking and automotive, and retail commerce are some of the biggest usecases in this space. It makes sense as well – enterprises want to provide customer service 24/7 but do not necessarily want to pay for human agents – they need chatbots to be effective to understand users requests and respond appropriately. In terms of commerce, retailers and food delivery brands are trying to enable users to make purchases through conversational interfaces.`}</p>
    <p>{`We believe conversational interfaces are the natural evolution of human computer interaction. Chatbots and voice apps are maturing as more brands move the initiatives out of innovation and into the business units. There is a lot of opportunity in this space and we look forward to seeing what both brands and developers build in the future.`}</p>
    <h2>{`Quotes from the experts`}</h2>
    <p>{`Alex Weidauer, Co-founder/CEO, `}<a parentName="p" {...{
        "href": "https://rasa.com/"
      }}>{`Rasa`}</a></p>
    <table><tbody><tr><td width="50%">**Biggest trends in 2018**</td><td width="50%">**Predictions for 2019**</td></tr><tr><td><span style={{
              "fontWeight": "400"
            }}>1) The world is still waiting for the “killer bot” although Google Duplex came pretty close</span><span style={{
              "fontWeight": "400"
            }}>2) Companies that have identified value adding use cases significantly invest into in-house teams to work on conversational AI – esp. in banking (both legacy banks + neo banks)</span>
          </td><td><span style={{
              "fontWeight": "400"
            }}>1) We still won’t see a “killer bot” but more and more “contextual AI assistants” (level 3) that go beyond answering simple questions</span><span style={{
              "fontWeight": "400"
            }}>2) We’ll see some significant research breakthroughs that will make it easier to build “level 3” contextual assistants</span>
            <span style={{
              "fontWeight": "400"
            }}>
  3) We’ll continue to see GAFMI and other tech companies like Twilio to
  double-down on conversational AI, e.g. through bot startup acquisitions
            </span>
          </td></tr></tbody></table>
    <p>{`Allen Firstenberg, `}<a parentName="p" {...{
        "href": "https://spiders.com"
      }}>{`Objective Consulting `}</a></p>
    <table>
  <tbody>
    <tr>
      <td width="50%">**Biggest trends in 2018**</td>
      <td width="50%">**Predictions for 2019**</td>
    </tr>
    <tr>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          Desire for companies to find multi-platform conversational solutions
          for their existing content.
        </span>
      </td>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          Companies will continue to struggle to get past the “app” model,
          although more and more will find that conversations present a
          different way to address their users’ needs. Platforms will continue
          to mature to offer more and better features that address these needs.
        </span>
      </td>
    </tr>
  </tbody>
    </table>
    <p>{`Andy Webb, Head of Product – Voice + AI, `}<a parentName="p" {...{
        "href": "https://www.bbc.com/"
      }}>{`BBC `}</a></p>
    <table>
  <tbody>
    <tr>
      <td width="50%">**Biggest trends in 2018**</td>
      <td width="50%">**Predictions for 2019**</td>
    </tr>
    <tr>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          A move to standardisation of user interactions through the use of
          API’s, building common ingest frameworks for content and leaving the
          interaction design with the platform.
        </span>
      </td>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          2 main predictions. One is a continuation of the move of platforms to
          build a common interaction model for publishers, the 2nd is to start
          to see greater AI-AI interoperability and eco-systems evolve.
        </span>
      </td>
    </tr>
  </tbody>
    </table>
    <p>{`Andy Mauro, CEO, `}<a parentName="p" {...{
        "href": "https://www.automat.ai/"
      }}>{`Automat `}</a></p>
    <table>
  <tbody>
    <tr>
      <td width="50%">**Biggest trends in 2018**</td>
      <td width="50%">**Predictions for 2019**</td>
    </tr>
    <tr>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          If by trend you mean hype, definitely voice.{" "}
        </span>
      </td>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          Conversational ads will blow up and be everywhere cementing that the
          meaningful “bot” innovation of the last few years is Conversational
          Marketing, not service and support
        </span>
      </td>
    </tr>
  </tbody>
    </table>
    <p>{`Audrey Wu,Co-founder/CEO, `}<a parentName="p" {...{
        "href": "https://convrg.io/"
      }}>{`Convrg `}</a></p>
    <table>
  <tbody>
    <tr>
      <td width="50%">**Biggest trends in 2018**</td>
      <td width="50%">**Predictions for 2019**</td>
    </tr>
    <tr>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          The rise of voice and all the biggest tech players in the world trying
          to find a place in this ecosystem.
        </span>
      </td>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          Branded experiences will continue to rise, especially in ecommerce use
          cases. Alexa and Google Home will continue to dominate the smart
          speaker space. Google assistant will start gaining traction for iOs
          users because frankly, it’s a better voice AI product than Siri.
        </span>
      </td>
    </tr>
  </tbody>
    </table>
    <p>{`Austin Arensberg, Principal, `}<a parentName="p" {...{
        "href": "https://scrum.vc/"
      }}>{`Scrum Ventures `}</a></p>
    <table>
  <tbody>
    <tr>
      <td width="50%">**Biggest trends in 2018**</td>
      <td width="50%">**Predictions for 2019**</td>
    </tr>
    <tr>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          Customer call center voice to text analytics. Numerous startups are
          using Google and Amazon APIs to build new services for call centers
          such as emotional, linguistic, and prosodic analysis on calls.
          Customer service agents and sales reps are now armed with far more
          information about their effectiveness than ever before.{" "}
        </span>
      </td>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          The rise of branded voices. More companies will invest money into
          creating voices that match their brand’s aspirations and customer
          targets.
        </span>
      </td>
    </tr>
  </tbody>
    </table>
    <p>{`Ben Parr, Co-founder/President, `}<a parentName="p" {...{
        "href": "https://octaneai.com/"
      }}>{`Octane`}</a></p>
    <table>
  <tbody>
    <tr>
      <td width="50%">**Biggest trends in 2018**</td>
      <td width="50%">**Predictions for 2019**</td>
    </tr>
    <tr>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          The biggest trend of 2018 was the rise of bots for ecommerce — no
          question. Thousands of merchants and ecommerce businesses adopted
          chatbots for automated customer support, retargeting, and customer
          acquisition. Shopify stores in particular saw 3 to 25 percent
          increases in revenue simply by adding a chatbot to their marketing
          arsenal.
        </span>
      </td>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          Chatbots will become much more intelligent in 2019. For the last few
          years, we have seen simple chatbots that can do many of the things
          bots do with email. In 2019 however, we will see chatbots that start
          to really push the boundaries of what AI can do.
        </span>
      </td>
    </tr>
  </tbody>
    </table>
    <p>{`Brandon Kaplan, Founder / CEO, `}<a parentName="p" {...{
        "href": "http://www.skilledcreative.com/"
      }}>{`Skilled Creative `}</a></p>
    <table>
  <tbody>
    <tr>
      <td width="50%">**Biggest trends in 2018**</td>
      <td width="50%">**Predictions for 2019**</td>
    </tr>
    <tr>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          Multi-Modal and Voice SEO were the most key feature trends we saw in
          terms of “inside baseball” industry trends for developers and
          marketers. In terms of entire platform trends, the biggest trend is
          still simply distribution. Growth in market size and market share by
          Amazon and Google in the conversation space dominated headlines, and
          the features that both platforms were releasing in waves to try to
          garner attention by consumers and developers. We’re in an awkward
          adolescence right now, where we’ve had a growth spurt in distribution,
          but haven’t reached our potential in terms of what the platform can
          do. 2019 is the time more that functional growth.
        </span>
      </td>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          In 2008/2009, the first few years of the iPhone, the most popular apps
          were a lightsaber app and a fart app. it wasn’t until developer tools
          and functional capabilities matured that we realized the potential of
          apps, and we finally created venmo, vine, instagram, etc. We are still
          in lightsaber app mode right now, with fairly simple conversational
          apps, being deployed by internal teams or creative technologists who
          are getting their sea legs with the platforms. In 2019 we see much
          more matured experiences being launched, leveraging more advanced data
          integrations, machine learning, better multimodal visual assets,
          higher production value, and true business tools such as contact
          permissions and VOICE SEO. We also predict that brands will begin to
          allocate very real marketing budgets behind their conversational app
          launches, resulting in better discovery, and more stickiness in terms
          of retention. 2019 is the make or break year for conversational
          stickiness across the consumer platforms. Our recommendation is for
          everyone to bring their best, because it’s going to be a space race.
        </span>
      </td>
    </tr>
  </tbody>
    </table>
    <p>{`Bree Glaeser, Director of Shopper Innovation `}{`&`}{` Voice Practice Lead, `}<a parentName="p" {...{
        "href": "http://www.themarsagency.com"
      }}>{`The Mars Agency `}</a></p>
    <table><tbody><tr><td width="50%">**Biggest trends in 2018**</td><td width="50%">**Predictions for 2019**</td></tr><tr><td><span style={{
              "fontWeight": "400"
            }}>High-production media related skills, like the Dunkirk Alexa Skill or Sesame Street’s Esme and Roy.</span></td><td><span style={{
              "fontWeight": "400"
            }}>1) Smart speakers will be used much more for shopping at home, and we will see the first big brands bring voice into the physical store. </span><span style={{
              "fontWeight": "400"
            }}>2) Voice &amp; visual activations on smart speakers will become the new standard.</span>
            <span style={{
              "fontWeight": "400"
            }}>
  3) Brands will make progress in figuring out who within their organizations
  will own Voice, and as a result we will likely see a saturation of branded
  skills and actions by the end of the 2019 year. This means the ‘first mover
  advantage’ window will close in the first half of the year.
            </span>
            <span style={{
              "fontWeight": "400"
            }}>
  4) Media companies will continue to dominate in terms of skill engagement, and
  we may see interesting partnerships between brand and media companies.
            </span>
            <span style={{
              "fontWeight": "400"
            }}>
  5) Voice will become a mainstream part of life as it continues to infiltrate
  homes, cars, hotels, and other public spaces.
            </span>
          </td></tr></tbody></table>
    <p>{`Bret Kinsella, Editor, `}<a parentName="p" {...{
        "href": "https://voicebot.ai/"
      }}>{`Voicebot.ai `}</a></p>
    <table>
  <tbody>
    <tr>
      <td width="50%">**Biggest trends in 2018**</td>
      <td width="50%">**Predictions for 2019**</td>
    </tr>
    <tr>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          The dominant voice assistant trend in 2018 was the language
          localization that brought exposure to more countries. This included
          both smart speaker and smartphone expansion. A secondary trend was
          added feature depth on the leading platforms as they raced for feature
          parity and looked for ways to get an edge on competitors.{" "}
        </span>
      </td>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          In 2019, the voice assistant competitive focus will shift from
          language support to extension to new devices. This will include both
          surface expansion and identifying new use cases based on
          device-specific contexts.{" "}
        </span>
      </td>
    </tr>
  </tbody>
    </table>
    <p>{`Connor Cirillo, Conversational Marketing Manager, `}<a parentName="p" {...{
        "href": "https://www.hubspot.com/"
      }}>{`Hubspot `}</a></p>
    <table>
  <tbody>
    <tr>
      <td width="50%">**Biggest trends in 2018**</td>
      <td width="50%">**Predictions for 2019**</td>
    </tr>
    <tr>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          Personal assistants like Amazon Alexa and Google Home continued to
          become the “center of gravity” for consumers. These companies released
          more tools to entrench themselves in consumers lives, earlier on.
          Interactive storytelling may seem small today, but it’s having a huge
          impact on the next generation of consumers. People growing up today
          expect to speak their wants into the world and assistants make it
          happen. That’s a fundamental shift in how interactions (and business)
          will happen in the future.
        </span>
      </td>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          Facebook speeds up the monetization efforts of Messenger, WhatsApp,
          and even Instagram messaging. On stage at F8 in April 2019, Mark
          Zuckerberg announces open WhatsApp APIs (a la Messenger in 2016). More
          tools come out that help businesses tie the core Facebook product to
          messaging platforms. It also causes another wave of “chatbot hype”
          that suggests the adoption curve is further along than it might be.
        </span>
      </td>
    </tr>
  </tbody>
    </table>
    <p>{`Christian Brucculeri, CEO, `}<a parentName="p" {...{
        "href": "https://snaps.io/"
      }}>{`Snaps`}</a></p>
    <table>
  <tbody>
    <tr>
      <td width="50%">**Biggest trends in 2018**</td>
      <td width="50%">**Predictions for 2019**</td>
    </tr>
    <tr>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          We saw our customers expand their objectives with automation from
          single use cases into end-to-end experiences. Increasingly, we see
          enterprises that started with a single use case (e.g. shopping,
          service deflection) grow their ambitions to cover more of the customer
          journey, from awareness and engagement to post-purchase care and
          business process automation. I expect we’ll see this trend continue as
          more businesses realize that they can create impact across the
          customer journey.
        </span>
      </td>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          Enterprise brands are going to expand both the ‘what’ and the ‘where’
          as it relates to automation. If 2018 was about expanding use cases, we
          believe 2019 will be about expanding touch points as well, offering
          automated conversations in more places in their digital ecosystems.
        </span>
      </td>
    </tr>
  </tbody>
    </table>
    <p>{`Dave Witting, Partner, `}<a parentName="p" {...{
        "href": "http://rocketinsights.com/"
      }}>{`Rocket Insights`}</a></p>
    <table>
  <tbody>
    <tr>
      <td width="50%">**Biggest trends in 2018**</td>
      <td width="50%">**Predictions for 2019**</td>
    </tr>
    <tr>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          Further platform penetration &amp; device expansion.{" "}
        </span>
      </td>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          Google is going to start closing the gap with Amazon. Experience
          investment will dip as early adopters realize that discoverability
          &amp; repeat usage are low across the board.{" "}
        </span>
      </td>
    </tr>
  </tbody>
    </table>
    <p>{`Jenny Gao, Associate, `}<a parentName="p" {...{
        "href": "https://www.bvp.com/"
      }}>{`Bessemer Venture Partners`}</a></p>
    <table>
  <tbody>
    <tr>
      <td width="50%">**Biggest trends in 2018**</td>
      <td width="50%">**Predictions for 2019**</td>
    </tr>
    <tr>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          Conversational analytics being used for marketing trends{" "}
        </span>
      </td>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          1) will see more enterprise/office use cases emerge 2) voice
          interfaces will become table stakes for travel &amp; hospitality 3)
          voice / chat interface will be integrated heavily into children’s
          education and gaming
        </span>
      </td>
    </tr>
  </tbody>
    </table>
    <p>{`Lauren Kunze, CEO, `}<a parentName="p" {...{
        "href": "https://home.pandorabots.com"
      }}>{`Pandorabots `}</a></p>
    <table>
  <tbody>
    <tr>
      <td width="50%">**Biggest trends in 2018**</td>
      <td width="50%">**Predictions for 2019**</td>
    </tr>
    <tr>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          2018 had some memorable moments of backlash against our still severely
          over-hyped space, but the entry of nearly every CSP platform that
          provides any type of live chat or messaging service, or wants to, into
          the chatbot space, and the uptick in M&amp;A and funding activity, as
          well as steadily increasing enterprise case studies and demand, and
          the beta opening of new channels like WhatsApp and Apple Business
          Chat, were all strong signals of a healthy market. Bots are here to
          stay.
        </span>
      </td>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          I predict a SPAMpocalypse in the form of “Messenger Marketing.”
          “Business Messaging” may be a big business; email marketing is big.
          But personally I’m not interested in recreating email marketing, or
          email anything, or websites or apps in chat form, to spam people with
          wrong message, wrong time, wrong everything. In 2019 Pandorabots will
          continue to work on computers that can actually hold a conversation,
          and until we have a major AI breakthrough in natural language
          understanding and generation, I doubt there will be “paradigm” shifts
          to speak of in terms of talking or texting usefully with technology.{" "}
        </span>
      </td>
    </tr>
  </tbody>
    </table>
    <p>{`Lucas Ives, VP of Engineering, `}<a parentName="p" {...{
        "href": "https://www.pullstring.com/"
      }}>{`Pullstring `}</a></p>
    <table>
  <tbody>
    <tr>
      <td width="50%">**Biggest trends in 2018**</td>
      <td width="50%">**Predictions for 2019**</td>
    </tr>
    <tr>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          Fortune 500 companies allocating real budget for exploration of the
          voice computing space.
        </span>
      </td>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          The number of voice computing endpoints will continue to skyrocket,
          and it will become more obvious which direction the big companies are
          heading: i.e. is Alexa going to be a concierge or an oracle? One
          version of that world has a lot of potentially complicated
          implications for brands.
        </span>
      </td>
    </tr>
  </tbody>
    </table>
    <p>{`Max Child, Co-founder, `}<a parentName="p" {...{
        "href": "https://volleythat.com/"
      }}>{`Volley`}</a></p>
    <table>
  <tbody>
    <tr>
      <td width="50%">**Biggest trends in 2018**</td>
      <td width="50%">**Predictions for 2019**</td>
    </tr>
    <tr>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          Voice platforms starting to become real platforms (monetization and
          some retention tools)
        </span>
      </td>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          TV and car will become more popular outlets for voice-controlled
          software
        </span>
      </td>
    </tr>
  </tbody>
    </table>
    <p>{`Mehfuz Hossain, CEO/Co-founder, `}<a parentName="p" {...{
        "href": "https://smartloop.ai/"
      }}>{`Smartloop `}</a></p>
    <table>
  <tbody>
    <tr>
      <td width="50%">**Biggest trends in 2018**</td>
      <td width="50%">**Predictions for 2019**</td>
    </tr>
    <tr>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          Enterprise started to understand the value of conversation AI in
          automating their mundane tasks. It is not tied to one channel but
          overall the need to automate marketing and customer service.
        </span>
      </td>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          Automation through voice and chatbots.
        </span>
      </td>
    </tr>
  </tbody>
    </table>
    <p>{`Nico Acosta, Director of Product, `}<a parentName="p" {...{
        "href": "https://www.twilio.com/"
      }}>{`Twilio `}</a></p>
    <table>
  <tbody>
    <tr>
      <td width="50%">**Biggest trends in 2018**</td>
      <td width="50%">**Predictions for 2019**</td>
    </tr>
    <tr>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>Home Assistants</span>
      </td>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          Conversational IVRs become the norm, not the exception
        </span>
      </td>
    </tr>
  </tbody>
    </table>
    <p>{`Pat Higbie, Co-founder/CEO, `}<a parentName="p" {...{
        "href": "https://xappmedia.com/"
      }}>{`XAPPmedia `}</a></p>
    <table>
  <tbody>
    <tr>
      <td width="50%">**Biggest trends in 2018**</td>
      <td width="50%">**Predictions for 2019**</td>
    </tr>
    <tr>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          A duopoly crystalized between Amazon Alexa and Google Assistant to the
          point where consumers are using both assistants at scale and brands
          need to address both assistants in their voice ai strategies.{" "}
        </span>
      </td>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          Voice search in the form of implicit invocation of Google Assistant
          actions and nameless invocation of Alexa skills will become essential
          to the discovery of third party voice apps and a race will be on in
          every product category for position 1 in search across both platforms.{" "}
        </span>
      </td>
    </tr>
  </tbody>
    </table>
    <p>{`Ryan Maynard, CEO, `}<a parentName="p" {...{
        "href": "https://www.chatdynamo.com/"
      }}>{`ChatDynamo`}</a></p>
    <table>
  <tbody>
    <tr>
      <td width="50%">**Biggest trends in 2018**</td>
      <td width="50%">**Predictions for 2019**</td>
    </tr>
    <tr>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          Bots driving revenue for businesses. 2018 forced brands to take a
          closer look at their chatbot strategies and start associating clear
          monetary value to chatbot engagement. For e-commerce businesses this
          meant using the chatbot to drive sales. Shopping cart
          abandonment/re-engagement emerged as a clear reason why e-com
          businesses need chatbots and why Messenger cart abandonment follow up
          is more effective than email or display ad retargeting. For some non
          e-commerce brands this meant exploring new ways to monetize their
          chatbot engagement. Some brands started experimenting with paid
          chatbot referrals, lead generation, and other in-bot ad experiences.{" "}
        </span>
      </td>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          By the end of 2019, Facebook Messenger will no longer be the favorite
          platform for chatbot developers. As Facebook looks to restrict
          messaging and monetize “sponsored messages”- SMS will emerge as the
          new preferred medium. New platforms and tools will emerge to make it
          as easy to deploy cool SMS chatbot experiences in 2019 as it is to
          deploy cool Messenger experiences today.{" "}
        </span>
      </td>
    </tr>
  </tbody>
    </table>
    <p>{`Scott Ganz, Principal Conversation Designer, `}<a parentName="p" {...{
        "href": "https://www.intuit.com/"
      }}>{`Intuit `}</a></p>
    <table>
  <tbody>
    <tr>
      <td width="50%">**Biggest trends in 2018**</td>
      <td width="50%">**Predictions for 2019**</td>
    </tr>
    <tr>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          Banking and personal finance assistants really proliferated this year,
          and they’re a great use case for the medium.{" "}
        </span>
      </td>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          I think multi-modal interaction is coming, and while it may not reach
          the mainstream market in 2019, designers and developers are going to
          really start taking it seriously and working towards that goal.
          Reading and speech are the two easiest and fastest ways to converse,
          so they’re going to start working together sooner or later.{" "}
        </span>
      </td>
    </tr>
  </tbody>
    </table>
    <p>{`Sergio Cruz Passos, Co-founder/CTO, `}<a parentName="p" {...{
        "href": "https://www.blip.ai/"
      }}>{`Take/Blip `}</a></p>
    <table>
  <tbody>
    <tr>
      <td width="50%">**Biggest trends in 2018**</td>
      <td width="50%">**Predictions for 2019**</td>
    </tr>
    <tr>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          Brands are moving from PoC to ROI-based projects.
        </span>
      </td>
      <td>
        <span style={{
              "fontWeight": "400"
            }}>
          Brands will realize that chatbot is not equal to AI, but a set of
          tools and process needed to build, run and evolve their contacts in
          conversational apps.
        </span>
      </td>
    </tr>
  </tbody>
    </table>
    <p>{`Stephane Nguyen, VP Product Design, `}<a parentName="p" {...{
        "href": "https://www.assi.st/"
      }}>{`Assist `}</a></p>
    <table><tbody><tr><td width="50%">**Biggest trends in 2018**</td><td width="50%">**Predictions for 2019**</td></tr><tr><td>In 2018, many companies have tried to build a different type of experiences along the lines of gift finding, entertainment bots. On the other hand, we have noticed that the simple use cases that we all know work best, such as schedule appointments or order a beer.</td><td>I do believe banking and insurance will become a huge trend in 2019. The reason is use case and repeatability. As messaging becomes more and more popular, we strongly believe consumers will adopt automated experience with a use case they already know such as: check balance, bill payment or insurance claims.
          </td></tr></tbody></table>
    <p>{`Will Hall, CCO, `}<a parentName="p" {...{
        "href": "https://rain.agency/"
      }}>{`RAIN`}</a></p>
    <table><tbody><tr><td width="50%">**Biggest trends in 2018**</td><td width="50%">**Predictions for 2019**</td></tr><tr><td><span style={{
              "fontWeight": "400"
            }}>2018 was definitely the year of multi-modal. </span></td><td><span style={{
              "fontWeight": "400"
            }}>Multi-modal will be an ever-more important element of Voice; this should lay the groundwork for ad units within first-party voice queries. </span><span style={{
              "fontWeight": "400"
            }}>B2B applications of Voice will start to mature; the potential operational efficiencies of this integration could have staggering implications.</span>
          </td></tr></tbody></table>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io/"
        }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables enterprises and developers to increase engagement, acquisition, and monetization through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide conversation specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Alexa, Google Home, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      